<template>
  <div>
    <v-card>
      <v-tabs v-model="tab" class="wizard-step">
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- 1 -->
        <v-tab-item>
          <v-card>
            <v-card-text>
              <div>
                <h4 class="mb-10 font-weight-bold text-dark">
                  Operatsiya malutmolari
                </h4>
                <div class="row">
                  <div class="col-4">
                    <v-text-field
                      disabled
                      label="Operatsiya turi"
                      outlined
                      dense
                      :value="getCorpCardOper.operation_type"
                    ></v-text-field>
                  </div>
                  <div class="col-4">
                    <v-text-field
                      disabled
                      outlined
                      dense
                      label="Ochilgan sana"
                      :value="getCorpCardOper.oper_date | dateFilter"
                    ></v-text-field>
                  </div>

                  <div
                    class="col-4"
                    v-if="getCorpCardOper.amount !== undefined"
                  >
                    <v-text-field
                      disabled
                      outlined
                      dense
                      label="Summa"
                      :value="getCorpCardOper.amount.toLocaleString('zh-ZH')"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- 2 -->
        <v-tab-item>
          <v-card>
            <v-card-text>
              <div>
                <h4 class="mb-10 font-weight-bold text-dark">
                  Korporativ karta operatsiyalar
                </h4>
                <v-data-table
                  :headers="operationsHeaders"
                  :items="getCorpCardOper"
                  :items-per-page="5"
                  class="elevation-1"
                ></v-data-table>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      name: '',
      tab: null,
      items: [
        {
          tab: 'Operatsiya malutmolari'
        }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('getallCorpCardDetail', this.$route.params.id)
    this.$store.dispatch('getCorpCardsOper', this.$route.params.id)
  },
  computed: {
    getCorpCardOper() {
      return this.$store.state.requests.CorpCardOperDetails
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Operatsiya malutmolari' }
    ])
  }
}
</script>

<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>
